import {getIntegrationIcon} from '@/common/integrations';
import {CONNECT_SHOPIFY_INTEGRATION, GET_INTEGRATIONS} from '@/common/queries';
import {IntegrationType} from '@/common/types';
import {ModalBody, ModalHeader} from '@/components/Modal';
import {useAuthContext} from '@/hooks';
import {useMutation, useQuery} from '@apollo/client';
import {msg, Trans} from '@lingui/macro';
import {memo, useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {formatApolloError} from '../helpers';
import {useLingui} from '@lingui/react';
import NextUIUrlInput from '@/components/inputs/NextUIUrlInput';
import {URL_INPUT_PROPS} from '@/components/inputs';
import TrackButton from '@/components/buttons/TrackButton';
import {useAnalytics} from '@/common/analytics';

const INTEGRATION_TYPE = IntegrationType.shopify;
const SHOPIFY_STORE_SUFFIX = '.myshopify.com';

const OnboardingConnectShopify = memo(() => {
  const {_} = useLingui();
  const location = useLocation();
  const isOnboarding = location.pathname.includes('onboarding');
  const {user} = useAuthContext();
  const {reportIntegrationStart, reportIntegration3rdPartyServiceRedirect} =
    useAnalytics();
  const [requestError, setRequestError] = useState<string | undefined>();
  const [shopUrl, setShopUrl] = useState('');
  const [isPendingRedirect, setIsPendingRedirect] = useState(false);
  const [shopUrlTouched, setShopUrlTouched] = useState(false);
  const [connect, {loading: connectLoading}] = useMutation(
    CONNECT_SHOPIFY_INTEGRATION,
    {
      onCompleted: data => {
        reportIntegration3rdPartyServiceRedirect(
          INTEGRATION_TYPE,
          data.href,
        ).finally(() => {
          setIsPendingRedirect(true);
          window.location.href = data.href;
        });
      },
      onError: error => {
        setRequestError(formatApolloError(error));
      },
    },
  );
  const {data, loading: integrationLoading} = useQuery(GET_INTEGRATIONS, {
    variables: {
      filter: {
        userId: user?.id,
        type: INTEGRATION_TYPE,
      },
      order: {type: 'ASC'},
      first: 1,
    },
    skip: !user?.id,
    fetchPolicy: 'cache-and-network',
  });
  const isConnected = Boolean(data?.connection.edges?.length);
  const shopUrlError = useMemo(() => {
    if (requestError) {
      return requestError;
    }

    if (!shopUrlTouched) {
      return null;
    }

    if (!shopUrl) {
      return _(msg`Required`);
    }

    return null;
  }, [requestError, shopUrl, _, shopUrlTouched]);

  const onValueChange = useCallback((value: string) => {
    setRequestError(undefined);
    setShopUrl(value);
  }, []);

  return (
    <>
      <ModalHeader>
        <div className="flex w-full items-center gap-3 text-base font-medium">
          <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-[0.625rem] bg-[#223316]">
            <img
              src={getIntegrationIcon(INTEGRATION_TYPE, 'small')}
              alt="Shopify"
              className="h-6 w-6 object-contain"
            />
          </div>
          <h1 className="font-medium">
            <Trans>Supercharge with Shopify</Trans>
          </h1>
        </div>
      </ModalHeader>
      <ModalBody className="mx-auto w-full max-w-lg gap-8 p-5">
        <NextUIUrlInput
          label={_(msg`Your Shopify URL`)}
          placeholder={_(msg`my-store-name`)}
          {...URL_INPUT_PROPS}
          startContent={null}
          endContent={
            <div className="flex min-h-full items-center border-l-[thin] border-default-200 pl-3 text-sm text-foreground/75">
              {SHOPIFY_STORE_SUFFIX}
            </div>
          }
          classNames={{
            label: '!text-foreground/50 font-normal',
            input: 'placeholder:!text-foreground/40',
          }}
          value={shopUrl}
          onValueChange={onValueChange}
          onBlur={() => setShopUrlTouched(true)}
          isInvalid={Boolean(shopUrlError)}
          errorMessage={shopUrlError}
        />
        <div className="flex flex-col gap-4 rounded-2xl border border-foreground/15 p-4 text-sm text-foreground/60">
          <h2 className="font-medium text-foreground">
            <Trans>Where can I find my Shopify URL?</Trans>
          </h2>
          <div>
            1. Go to{' '}
            <span className="text-foreground">https://admin.shopify.com/</span>
            <br />
            2. Pick the store you want to connect in the top right corner
            <br />
            3. The navigation bar will have a URL that looks like
            https://admin.shopify.com/store/
            <span className="font-semibold text-foreground">my-store-name</span>
            <br />
            4. Copy the store name, for example{' '}
            <span className="font-semibold text-foreground">
              my-store-name
            </span>{' '}
            and paste it into the input field
          </div>
        </div>
        <TrackButton
          aria-label={_(msg`Connect Shopify`)}
          data-amp-track-label={`Connect Shopify`}
          className="w-full rounded-xl bg-primary px-4 py-2 text-sm font-medium text-foreground"
          isDisabled={
            !shopUrl ||
            Boolean(shopUrlError) ||
            isConnected ||
            integrationLoading
          }
          isLoading={connectLoading || isPendingRedirect}
          onPress={() => {
            setRequestError(undefined);

            const url = `${
              shopUrl
                .trim()
                .toLowerCase()
                .split('?')[0]
                .split(SHOPIFY_STORE_SUFFIX)[0]
            }${SHOPIFY_STORE_SUFFIX}`;

            reportIntegrationStart(INTEGRATION_TYPE).finally(() => {
              connect({
                variables: {
                  input: {
                    shopUrl: url,
                    source: isOnboarding ? 'onboarding' : 'videogen',
                    callbackPath:
                      location.pathname
                        .slice(1)
                        .replace('/connect-shopify', '') + location.search,
                  },
                },
              });
            });
          }}>
          <Trans>Connect</Trans>
        </TrackButton>
      </ModalBody>
    </>
  );
});

OnboardingConnectShopify.displayName = 'ConnectShopify';

export default OnboardingConnectShopify;
