import {memo, useCallback, useMemo, useRef, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {
  useMotionValue,
  useTransform,
  motion,
  animate,
  MotionValue,
  useTime,
} from 'framer-motion';
import {useEffect} from 'react';
import NoisePNG from './noise.png';
import {useResizeObserver} from '@/hooks';

interface Props {
  centerRef: React.RefObject<HTMLDivElement>;
  startRadius?: number;
  radiusIncrement?: number;
  isSuccess?: boolean;
  className?: string;
  radiusModifier?: (index: number, increment: number) => number;
}

const rippleSize = 10;

const AnimatedCircle = memo(
  ({
    radius,
    centerX,
    centerY,
    gradientId,
    currentStrokeColor,
    strokeOpacity,
    rippleProgress,
    index,
    totalCircles,
  }: {
    radius: number;
    centerX: number;
    centerY: number;
    gradientId: string;
    currentStrokeColor: MotionValue<string>;
    strokeOpacity: number;
    rippleProgress: MotionValue<number>;
    index: number;
    totalCircles: number;
  }) => {
    // Calculate the duration for one complete wave to travel through all circles
    const totalWaveDuration = Math.PI * 2;
    // Extend the animation window to ensure smooth completion
    const singleCircleDuration = totalWaveDuration * 0.25;

    // Calculate when this circle's animation should start and end
    // Distribute the start points evenly but give more room for completion
    const startPoint = (index / totalCircles) * (totalWaveDuration * 0.5);
    const peakPoint = startPoint + singleCircleDuration / 2;
    const endPoint = startPoint + singleCircleDuration;

    // Transform ripple progress into radius modification
    const rippleModifier = useTransform(rippleProgress, value => {
      // Normalize the progress value to our wave cycle
      const normalizedValue = value % totalWaveDuration;

      // If we're not within our animation window, return 0
      if (normalizedValue < startPoint || normalizedValue > endPoint) {
        return 0;
      }

      // Calculate progress within our animation window
      let progress;
      if (normalizedValue < peakPoint) {
        // Ramp up
        progress = (normalizedValue - startPoint) / (singleCircleDuration / 2);
      } else {
        // Ramp down
        progress =
          1 - (normalizedValue - peakPoint) / (singleCircleDuration / 2);
      }

      // Clamp progress between 0 and 1 and apply smooth easing
      progress = Math.max(0, Math.min(1, progress));
      // Apply sine easing for smoother animation
      progress = Math.sin((progress * Math.PI) / 2);

      return progress * rippleSize;
    });

    // Combine base radius with ripple modifier
    const finalRadius = useTransform(() => {
      return radius + rippleModifier.get();
    });

    return (
      <motion.circle
        key={radius}
        cx={centerX}
        cy={centerY}
        r={finalRadius}
        fill={`url(#${gradientId})`}
        style={{
          stroke: currentStrokeColor,
          opacity: 0.6,
        }}
        strokeOpacity={strokeOpacity}
      />
    );
  },
);

AnimatedCircle.displayName = 'AnimatedCircle';

const defaultModifier = (index: number, increment: number) => {
  return (
    Math.min(Math.pow(Math.max(1, index - 2), 1.5) * 0.4, 10) * increment +
    index * increment
  );
};

const ConcentricCircles = memo(
  ({
    centerRef,
    startRadius = 122,
    radiusIncrement = 60,
    isSuccess = false,
    className,
    radiusModifier = defaultModifier,
  }: Props) => {
    const measurementIntervalRef = useRef<any>(null);
    const successProgress = useMotionValue(0);
    const screenX = window.innerWidth;
    const screenY = document.documentElement.clientHeight;
    const [centerY, setCenterY] = useState(screenY / 2 - 120);
    const centerX = screenX / 2;

    // Add ripple animation using useTime
    const time = useTime();
    const rippleProgress = useTransform(time, t => {
      // Adjust speed of the ripple (lower divisor = faster ripple)
      return (t / 500) % (Math.PI * 2);
    });

    const currentGradientColor = useTransform(
      successProgress,
      [0, 1],
      ['rgb(96, 0, 231)', 'rgb(0, 231, 8)'],
    );

    const currentStrokeColor = useTransform(
      successProgress,
      [0, 1],
      ['rgb(150, 94, 255)', 'rgb(0, 231, 8)'],
    );

    // Transform maxPossibleRadius based on centerX and centerY
    const maxPossibleRadius = useMemo(() => {
      const x = centerX;
      const y = centerY;
      const windowWidth = screenX;
      const windowHeight = screenY;

      return Math.sqrt(
        Math.pow(Math.max(x, windowWidth - x), 2) +
          Math.pow(Math.max(y, windowHeight - y), 2),
      );
    }, [centerX, centerY, screenX, screenY]);

    // Generate circles data based on maxPossibleRadius
    const circles = useMemo(() => {
      const circlesArray = [];
      let circleCount = 0;
      let currentRadius = startRadius;

      while (currentRadius <= maxPossibleRadius) {
        circlesArray.push({
          radius: startRadius + radiusModifier(circleCount, radiusIncrement),
          gradientId: `circleGradient${circleCount}`,
          opacity: circleCount === 0 ? 0.35 : circleCount === 1 ? 0.3 : 0.2,
          strokeOpacity:
            circleCount === 0 ? 0.5 : circleCount === 1 ? 0.3 : 0.2,
        });
        circleCount++;
        const nextIncrement = radiusModifier(circleCount, radiusIncrement);
        currentRadius = startRadius + nextIncrement;
      }
      return circlesArray;
    }, [startRadius, radiusIncrement, radiusModifier, maxPossibleRadius]);

    const ellipseWidth = Math.max(window.innerWidth * 0.7, 1200);
    const ellipseHeight = Math.min(screenY, 1000) * 1.07;
    const ellipseY = screenY + ellipseHeight * 0.55;

    useEffect(() => {
      animate(successProgress, isSuccess ? 1 : 0, {duration: 0.6});
    }, [isSuccess]);

    const onCenterMeasure = useCallback(() => {
      if (!centerRef.current) {
        return;
      }

      const rect = centerRef.current.getBoundingClientRect();

      setCenterY(rect.top + rect.height / 2);
    }, [centerRef]);

    useEffect(() => {
      // additional periodic measurement since ResizeObserver is not always called when offsetTop changes
      measurementIntervalRef.current = setInterval(() => {
        onCenterMeasure();
      }, 150);

      return () => {
        clearInterval(measurementIntervalRef.current);
      };
    }, [onCenterMeasure]);

    useResizeObserver(centerRef, onCenterMeasure);

    return (
      <svg
        className={twMerge('h-full w-full', className)}
        viewBox={`0 0 ${screenX} ${screenY}`}
        xmlns="http://www.w3.org/2000/svg">
        <defs>
          {circles.map(({gradientId, opacity}) => (
            <radialGradient
              key={gradientId}
              id={gradientId}
              cx="50%"
              cy="50%"
              r="50%"
              fx="50%"
              fy="50%">
              <stop
                offset="0%"
                style={{
                  stopColor: 'rgb(0, 0, 0)',
                  stopOpacity: opacity,
                }}
              />
              <motion.stop
                offset="100%"
                style={{
                  stopOpacity: opacity,
                  stopColor: currentGradientColor,
                }}
              />
            </radialGradient>
          ))}
          <pattern
            id="noiseOverlay"
            patternUnits="userSpaceOnUse"
            width="32"
            height="32">
            <image href={NoisePNG} width="48" height="48" />
          </pattern>
          {/* <filter id="ellipseBlur" colorInterpolationFilters="sRGB">
            <feGaussianBlur in="SourceGraphic" stdDeviation="48" />
          </filter> */}
          <radialGradient id="vignette">
            <stop offset="0%" stopColor="white" stopOpacity="1" />
            <stop offset="50%" stopColor="white" stopOpacity="1" />
            <stop offset="80%" stopColor="white" stopOpacity="0.5" />
            <stop offset="100%" stopColor="white" stopOpacity="0" />
          </radialGradient>

          <mask id="vignettemask">
            <circle
              cx={centerX}
              cy={centerY}
              r={maxPossibleRadius}
              fill="url('#vignette')"
            />
          </mask>
        </defs>

        <g mask="url(#vignettemask)">
          {circles.map(({radius, gradientId, strokeOpacity}, index, array) => (
            <AnimatedCircle
              key={radius}
              centerX={centerX}
              centerY={centerY}
              radius={radius}
              gradientId={gradientId}
              currentStrokeColor={currentStrokeColor}
              strokeOpacity={strokeOpacity}
              rippleProgress={rippleProgress}
              index={index}
              totalCircles={array.length}
            />
          ))}
        </g>

        <rect
          width="100%"
          height="100%"
          fill="url(#noiseOverlay)"
          style={{mixBlendMode: 'overlay', opacity: 0.6}}
        />

        {/* <ellipse
          cx={centerX}
          cy={ellipseY}
          rx={ellipseWidth}
          ry={ellipseHeight}
          fill="black"
          filter="url(#ellipseBlur)"
        /> */}
        <g className="transform-gpu" style={{filter: 'blur(24px)'}}>
          {Array.from({length: 60}).map((_, index) => {
            const progress = index / 59; // 0 to 1
            const scale = 1 - progress * 0.3; // Scale from 1 to 0.7

            return (
              <ellipse
                key={index}
                cx={centerX}
                cy={ellipseY}
                rx={ellipseWidth * scale}
                ry={ellipseHeight * scale}
                fill="black"
                opacity={0.001 + 0.95 ** (60 - index)} // Gradually decrease opacity
              />
            );
          })}
        </g>
      </svg>
    );
  },
);

ConcentricCircles.displayName = 'ConcentricCircles';

export default ConcentricCircles;
