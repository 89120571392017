'use client';

import PortalLogo from '@/components/icons/PortalLogo';
import {memo} from 'react';

const OnboardingHeader = memo(() => {
  return (
    <div className="sticky flex h-[3.25rem] w-full max-w-[min(100dvw,42rem)] shrink-0 items-center justify-center gap-4 md:pt-16">
      <PortalLogo className="flex shrink-0 text-foreground" />
    </div>
  );
});

OnboardingHeader.displayName = 'OnboardingHeader';

export default OnboardingHeader;
