'use client';

import {FC, Suspense, lazy} from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouteObject,
} from 'react-router-dom';
import {wrapCreateBrowserRouter} from '@sentry/react';
import PortalSpinner from './components/PortalSpinner';
import ErrorPage from './ErrorPage';
import StripeSuccess from './routes/StripeSuccess';
import StripeMobileRedirect from './routes/StripeMobileRedirect';
import LegalDocument from './routes/legal/LegalDocument';
import ProtectedRoute from './routes/components/ProtectedRoute';
// import NavigateWithSearch from './components/NavigateWithSearch';
// import Login from './routes/login/Login';
// import LoginOptions from './routes/login/LoginOptions';
// import LoginSignUpInvited from './routes/login/LoginSignUpInvited';
// import LoginProfile from './routes/login/LoginProfile';
// import AppInvite from './routes/AppInvite';
// import ResetPassword from './routes/resetPassword/ResetPassword';
// import ResetPasswordEmail from './routes/resetPassword/ResetPasswordEmail';
// import ResetPasswordInvited from './routes/resetPassword/ResetPasswordInvited';
// import ResetPasswordNewPassword from './routes/resetPassword/ResetPasswordNewPassword';
import AuthRedirect from './routes/AuthRedirect';
import {
  SearchParamsProvider,
  ErrorParamHandlerProvider,
} from './components/providers';
import Root from './routes/Root';
import MetaAdsAuth from './routes/MetaAdsAuth';
// import TikTokAuth from './routes/TikTokAuth';

// import NewCampaign from './routes/NewCampaign';
// import EditAsset from './routes/EditAsset';
// import PreviewAsset from './routes/PreviewAsset';
// import FineTuneCampaignSettings from './routes/FineTuneCampaignSettings';
// import CampaignCreationSuccess from './routes/CampaignCreationSuccess';
// import {FlowProvider} from './components/providers/FlowProvider';
// import {Live} from './routes/live/Live';
// import ConnectShopify from './routes/ConnectShopify';
// import AdDetails from './routes/AdDetails';
// import {LEGACY_AUTH_ENABLED} from './config';
// import NavigateWithSearch from './components/NavigateWithSearch';

// import Profile from './routes/Profile';
// import DeleteAccountForm from './routes/Profile/forms/DeleteAccountForm';
// import SelectDefaultMetaPreset from './routes/Profile/routes/SelectDefaultMetaPreset';
// import MetaUsers from './routes/Profile/routes/MetaUsers';
// import SelectMetaFlowPreset from './routes/SelectMetaFlowPreset';
// import EditMetaPreset from './routes/Profile/routes/EditMetaPreset';
import PageContainer from './components/PageContainer';

// import WhatsappNotificationsEnable from './routes/WhatsappNotificationsEnable';
// import WhatsappNotificationsDisable from './routes/WhatsappNotificationsDisable';

import Onboarding from './routes/Onboarding';
import OnboardingConnectShopify from './routes/Onboarding/OnboardingConnectShopify';

/* eslint-disable react-refresh/only-export-components */

const Loader: FC = () => (
  <PortalSpinner
    classNames={{
      base: 'backdrop-blur-sm fixed inset-0 z-[1000]',
    }}
    size="lg"
  />
);

const withSuspense = (Component: FC) => {
  const SuspenseWrapper: FC = () => (
    <Suspense fallback={<Loader />}>
      <Component />
    </Suspense>
  );

  return SuspenseWrapper;
};

const Legal = withSuspense(lazy(() => import('./routes/legal')));
const TermsRoute = withSuspense(() => (
  <LegalDocument file={import('@/assets/legal/terms.md?raw')} />
));
const PrivacyRoute = withSuspense(() => (
  <LegalDocument file={import('@/assets/legal/privacy.md?raw')} />
));
const DataProcessingAddendumRoute = withSuspense(() => (
  <LegalDocument
    file={import('@/assets/legal/data-processing-addendum.md?raw')}
  />
));
const SubprocessorsRoute = withSuspense(() => (
  <LegalDocument file={import('@/assets/legal/subprocessors.md?raw')} />
));
const NotFound = withSuspense(lazy(() => import('./routes/NotFound')));

const authPath: RouteObject = {
  path: 'auth',
  element: <Outlet />,
  children: [
    {
      path: 'redirect',
      element: <AuthRedirect />,
    },
  ],
  // children: LEGACY_AUTH_ENABLED
  //   ? [
  //       {
  //         path: 'login',
  //         element: <Login />,
  //         children: [
  //           {
  //             index: true,
  //             element: <LoginOptions />,
  //           },
  //           {
  //             path: 'invited',
  //             element: <LoginSignUpInvited />,
  //           },
  //           {
  //             path: 'profile',
  //             element: <LoginProfile />,
  //           },
  //         ],
  //       },
  //       // {
  //       //   path: 'reset-password',
  //       //   element: <ResetPassword />,
  //       //   children: [
  //       //     {
  //       //       path: 'request',
  //       //       element: <ResetPasswordEmail />,
  //       //     },
  //       //     {
  //       //       path: 'requested',
  //       //       element: <ResetPasswordInvited />,
  //       //     },
  //       //     {
  //       //       path: 'set',
  //       //       element: <ResetPasswordNewPassword />,
  //       //     },
  //       //   ],
  //       // },
  //       {
  //         path: 'invite',
  //         element: <AppInvite />,
  //       },
  //       {
  //         path: 'redirect',
  //         element: <AuthRedirect />,
  //       },
  //       {path: '', element: <NavigateWithSearch to="login" />},
  //       {path: '*', element: <NavigateWithSearch to="login" />},
  //     ]
  //   : [
  //       {
  //         path: 'redirect',
  //         element: <AuthRedirect />,
  //       },
  //     ],
};
// const connectShopifyPath: RouteObject = {
//   path: 'connect-shopify',
//   element: <ConnectShopify />,
// };

const router = wrapCreateBrowserRouter(createBrowserRouter)([
  {
    path: '/',
    element: (
      <SearchParamsProvider>
        <ErrorParamHandlerProvider>
          <Root />
        </ErrorParamHandlerProvider>
      </SearchParamsProvider>
    ),
    errorElement: <ErrorPage />,
    children: [
      authPath,
      // {
      //   path: 'live',
      //   element: (
      //     <ProtectedRoute>
      //       <PageContainer color="primary">
      //         <Live />
      //       </PageContainer>
      //     </ProtectedRoute>
      //   ),
      //   children: [
      //     connectShopifyPath,
      //     {
      //       path: 'enable-whatsapp-notifications',
      //       element: <WhatsappNotificationsEnable />,
      //     },
      //     {
      //       path: 'disable-whatsapp-notifications',
      //       element: <WhatsappNotificationsDisable />,
      //     },
      //   ],
      // },
      // {
      //   path: 'ad/:id',
      //   element: (
      //     <ProtectedRoute>
      //       <PageContainer>
      //         <AdDetails />
      //       </PageContainer>
      //     </ProtectedRoute>
      //   ),
      //   children: [connectShopifyPath],
      // },
      {
        path: 'stripe-success',
        element: (
          <ProtectedRoute redirectTo="/onboarding">
            <StripeSuccess />
          </ProtectedRoute>
        ),
      },
      {
        path: 'stripe-mobile-redirect',
        element: <StripeMobileRedirect />,
      },
      {
        path: 'meta-ads-auth',
        element: <MetaAdsAuth />,
      },
      // {
      //   path: 'tiktok-auth',
      //   element: <TikTokAuth />,
      // },
      {
        path: 'legal',
        element: (
          <PageContainer>
            <Outlet />
          </PageContainer>
        ),
        children: [
          {
            path: '',
            element: <Legal />,
          },
          {
            path: 'terms',
            element: <TermsRoute />,
          },
          {
            path: 'privacy',
            element: <PrivacyRoute />,
          },
          {
            path: 'data-processing-addendum',
            element: <DataProcessingAddendumRoute />,
          },
          {
            path: 'subprocessors',
            element: <SubprocessorsRoute />,
          },
        ],
      },
      // {
      //   path: 'profile',
      //   element: (
      //     <ProtectedRoute>
      //       <PageContainer>
      //         <Profile />
      //       </PageContainer>
      //     </ProtectedRoute>
      //   ),
      //   children: [
      //     {
      //       path: 'delete-account',
      //       element: <DeleteAccountForm />,
      //     },
      //     {
      //       path: 'meta-users',
      //       element: <MetaUsers />,
      //     },
      //     {
      //       path: 'meta-presets',
      //       element: <SelectDefaultMetaPreset />,
      //       children: [
      //         {
      //           path: 'edit/:id?',
      //           element: <EditMetaPreset />,
      //         },
      //       ],
      //     },
      //     {
      //       path: 'enable-whatsapp-notifications',
      //       element: <WhatsappNotificationsEnable />,
      //     },
      //     {
      //       path: 'disable-whatsapp-notifications',
      //       element: <WhatsappNotificationsDisable />,
      //     },
      //   ],
      // },
      // {
      //   path: 'create',
      //   element: <Outlet />,
      //   children: [
      //     {
      //       path: ':runId?',
      //       element: (
      //         <FlowProvider>
      //           <PageContainer color="primary">
      //             <NewCampaign />
      //           </PageContainer>
      //         </FlowProvider>
      //       ),
      //       children: [
      //         authPath,
      //         {
      //           path: 'asset/edit/:id',
      //           element: <EditAsset />,
      //         },
      //         {
      //           path: 'asset/preview/:id',
      //           element: <PreviewAsset />,
      //         },
      //         {
      //           path: 'fine-tune',
      //           element: <FineTuneCampaignSettings />,
      //         },
      //         {
      //           path: 'meta-presets',
      //           element: <SelectMetaFlowPreset />,
      //           children: [
      //             {
      //               path: 'edit/:id?',
      //               element: <EditMetaPreset />,
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       path: ':runId/success',
      //       element: (
      //         <PageContainer color="primary">
      //           <CampaignCreationSuccess />
      //         </PageContainer>
      //       ),
      //     },
      //   ],
      // },
      {
        path: 'onboarding',
        element: (
          <PageContainer color="default">
            <Onboarding />
          </PageContainer>
        ),
        children: [
          {
            path: 'connect-shopify',
            element: <OnboardingConnectShopify />,
          },
        ],
      },
      {path: '', element: <Navigate to="/onboarding" replace />},
      {path: '*', element: <NotFound />},
    ],
  },
]);

export default router;
