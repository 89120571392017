import {memo, ReactNode, useState} from 'react';
import {useCheckoutSession} from '@/hooks';
import {Button, ButtonProps} from '@nextui-org/react';
import {msg, Trans} from '@lingui/macro';
import OnboardingHeader from '../OnboardingHeader';
import {useLingui} from '@lingui/react';
import {twMerge} from 'tailwind-merge';
import BackgroundWEBP from './background.webp';
import BasicStepsPNG from './basic-steps.png';
import AdvancedStepsPNG from './advanced-steps.png';
import CheckSVG from './check.svg';
import GlowPNG from './glow.png';

const TierButton = memo(
  ({
    title,
    tier,
    isSelected,
    isPrimary = false,
    ...props
  }: {
    title: ReactNode;
    tier: ReactNode;
    isSelected?: boolean;
    isPrimary?: boolean;
  } & Omit<ButtonProps, 'children'>) => (
    <Button
      radius="full"
      className={twMerge(
        'min-h-[53px] flex-1 px-6 text-background',
        isSelected
          ? isPrimary
            ? 'bg-[linear-gradient(0deg,#D2AFFF,#8966FF)] text-background'
            : 'bg-foreground text-background'
          : isPrimary
            ? '-mx-0.5 border-2 border-[#D2AFFF] bg-foreground/10 text-foreground/50'
            : 'bg-foreground/10 text-foreground/50',
      )}
      disableRipple
      {...props}>
      <div className="flex flex-col">
        <span className="text-base font-semibold">{title}</span>
        <span className="text-xs opacity-50">{tier}</span>
      </div>
    </Button>
  ),
);

TierButton.displayName = 'TierButton';

const Paywall = memo(() => {
  const {_} = useLingui();
  const search = new URLSearchParams(window.location.search);
  const searchString = search.toString();
  const searchStringWithQuestionMark = searchString ? '?' + searchString : '';
  const [selectedPlan, setSelectedPlan] = useState<number>(0);
  const {createCheckoutSession} = useCheckoutSession(() => ({
    successPath: 'stripe-success' + searchStringWithQuestionMark,
    cancelPath: window.location.pathname + searchStringWithQuestionMark,
  }));

  return (
    <>
      <div className="fixed inset-0 overflow-hidden">
        <div
          className="absolute -bottom-[40%] left-0 right-0 top-0 sm:-left-[2%] sm:-right-[2%]"
          style={{
            backgroundImage: `url("${BackgroundWEBP}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}></div>
      </div>

      <OnboardingHeader />
      <div className="flex max-w-[428px] flex-col gap-10 px-5 pb-72 pt-4 md:pt-12">
        <div className="flex flex-col gap-7 ">
          <div
            style={{WebkitTextFillColor: 'transparent'}}
            className="bg-[linear-gradient(0deg,#D2AFFF,#8966FF)] bg-clip-text text-center text-3xl font-bold">
            <Trans>
              One Step to
              <br />
              AI-Driven Growth
            </Trans>
          </div>
          <div className="flex flex-row gap-2">
            <TierButton
              title={_(msg`Basic`)}
              tier={_(msg`Stage 1`)}
              isSelected={selectedPlan === 0}
              onPress={() => setSelectedPlan(0)}
              isPrimary
            />
            <TierButton
              title={_(msg`Advanced`)}
              tier={_(msg`Stage 2`)}
              isSelected={selectedPlan === 1}
              onPress={() => setSelectedPlan(1)}
            />
            <TierButton
              title={_(msg`Enterprise`)}
              tier={_(msg`Stage 3`)}
              isSelected={selectedPlan === 2}
              onPress={() => setSelectedPlan(2)}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="shrink-0">
            {selectedPlan === 0 ? (
              <img src={BasicStepsPNG} alt="Basic Steps" />
            ) : (
              <img src={AdvancedStepsPNG} alt="Advanced Steps" />
            )}
          </div>
          <div className="flex flex-1 flex-col gap-5">
            <div className="h-[42px]">
              <div className="text-sm text-foreground/50">
                <Trans>Completed:</Trans>
              </div>
              <div className="text-[18px] tracking-tight">
                <Trans>Meta Ads Connected 🚀</Trans>
              </div>
            </div>
            <div className="h-[42px]">
              <div className="text-sm text-foreground/50">
                <Trans>Next Step:</Trans>
              </div>
              <div className="text-[18px] tracking-tight">
                <Trans>Subscribe to Launch Video Ads 🔥</Trans>
              </div>
            </div>
            <div className="h-[42px]">
              <div className="text-sm text-foreground/50">
                <Trans>Day 3:</Trans>
              </div>
              <div className="text-[18px] font-medium tracking-tight">
                <Trans>Watch Sales Roll In 🤑🤑🤑️</Trans>
              </div>
            </div>
            {selectedPlan === 1 ? (
              <div className="flex flex-col gap-4">
                <div
                  className="w-w-full h-0.5 bg-repeat-x"
                  style={{
                    backgroundImage:
                      'linear-gradient(to right, rgb(51, 51, 51) 50%, transparent 50%)',
                    backgroundSize: '16px 2px',
                  }}
                />
                <div className="flex flex-col gap-2">
                  <div className="text-[18px] font-medium tracking-tight">
                    <Trans>You Grow, We Adjust</Trans>
                  </div>
                  <div className="text-sm text-foreground/50">
                    <Trans>
                      Once you reach{' '}
                      <span className="text-foreground">100K impressions</span>,
                      we’ll automatically upgrade you to the Advanced tier — so
                      your growth never slows down. No action needed — just more
                      results.
                    </Trans>
                  </div>
                </div>
              </div>
            ) : null}
            {selectedPlan === 2 ? (
              <div className="flex flex-col gap-4">
                <div
                  className="w-w-full h-0.5 bg-repeat-x"
                  style={{
                    backgroundImage:
                      'linear-gradient(to right, rgb(51, 51, 51) 50%, transparent 50%)',
                    backgroundSize: '16px 2px',
                  }}
                />
                <div className="flex flex-col gap-2">
                  <div className="text-[18px] font-medium tracking-tight">
                    <Trans>You’ve Grown, Now, We Tailor</Trans>
                  </div>
                  <div className="text-sm text-foreground/50">
                    <Trans>
                      After{' '}
                      <span className="text-foreground">500K impressions</span>{' '}
                      , we customize your plan for even greater performance.
                      Full-service, AI-powered, and tailored to your business
                      needs.
                    </Trans>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <ul className="flex flex-col gap-4 rounded-[32px] border-[length:thin] border-foreground/20 p-5">
          <li className="flex items-center gap-2">
            <img src={CheckSVG} alt="Check" className="shrink-0" />
            <div className="flex-1">
              <Trans>Self-Improving AI Video Ads</Trans>
            </div>
          </li>
          <li className="flex items-center gap-2">
            <img src={CheckSVG} alt="Check" className="shrink-0" />
            <div className="flex-1">
              <Trans>Launch in Minutes, Get Sales in 36 hrs</Trans>
            </div>
          </li>
          <li className="flex items-center gap-2">
            <img src={CheckSVG} alt="Check" className="shrink-0" />
            <div className="flex-1">
              <Trans>
                Track Sales, Units, Impressions & Click-through-Rate in Real
                Time
              </Trans>
            </div>
          </li>
        </ul>
      </div>
      <div
        style={{
          background:
            'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 25%)',
        }}
        className="fixed bottom-0 left-1/2 flex w-full max-w-[428px] -translate-x-1/2 flex-col gap-4 px-5 pb-10 pt-20 text-base">
        <div className="text-center tracking-tight text-foreground/80">
          <Trans>🚀 Go live in minutes, first results within 36 hrs.</Trans>
        </div>
        <div className="relative -mx-5 flex h-16 w-[calc(100%+40px)] flex-col items-center rounded-full">
          <img
            src={GlowPNG}
            alt="Glow"
            className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 animate-pulse"
          />
          <Button
            radius="full"
            style={{
              boxShadow:
                '-1.44px 1.44px 5px 0px rgba(255, 255, 255, 0.75) inset, 1.08px 1.44px 8.641px 0px #FFF inset, -3.24px 3.24px 5.04px 0px rgba(255, 255, 255, 0.65) inset, 0px 8.641px 20px 0px rgba(0, 16, 163, 0.15) inset',
              background:
                'linear-gradient(314deg, #FFF 4.53%, #D4E2FF 116.86%, #AA9BFF 163.74%)',
            }}
            className="mx-auto h-16 w-[calc(100%-40px)] bg-foreground text-2xl font-bold tracking-[-0.17px] text-background data-[pressed=true]:scale-[0.99]"
            disableRipple
            onPress={() => createCheckoutSession()}>
            <Trans>Start my 7-Day Free Trial</Trans>
          </Button>
        </div>
        <div className="text-center tracking-tight text-foreground/50 ">
          <Trans>
            Then <span className="text-foreground">$249</span>/month.
          </Trans>{' '}
          <>
            {selectedPlan === 0 ? (
              <Trans>Pricing scales after 100K impressions.</Trans>
            ) : selectedPlan === 1 ? (
              <Trans>Pricing scales after 500K impressions.</Trans>
            ) : (
              <Trans>Custom pricing applies after 500K impressions.</Trans>
            )}
          </>{' '}
          <span className="text-foreground">
            <Trans>Cancel anytime</Trans>
          </span>
          .
        </div>
      </div>
    </>
  );
});

Paywall.displayName = 'Paywall';

export default Paywall;
