import ModalOutlet from '@/components/ModalOutlet';
import {memo, useRef} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import OnboardingHeader from './OnboardingHeader';
import {useAppContext, useAuthContext, useSearchParamsContext} from '@/hooks';
import PortalSpinner from '@/components/PortalSpinner';
import {useLingui} from '@lingui/react';
import {useAnalytics} from '@/common/analytics';
import {useMutation, useQuery} from '@apollo/client';
import {META_LOGIN} from '@/common/mutations';
import {Trans} from '@lingui/macro';
import {setIsOnboardingFlow, setParamsAfterLogin} from '@/utils';
import {GET_INTEGRATIONS} from '@/common/queries';
import {IntegrationType} from '@/common/types';
import {twMerge} from 'tailwind-merge';
import ShopifyPNG from './shopify.png';
import AppStoreSVG from './app-store.svg';
import GooglePlaySVG from './google-play.svg';
import MetaPNG from './meta.png';
import ShopifyShadowPNG from './shopify-shadow.png';
import MetaShadowPNG from './meta-shadow.png';
import Background from './background.png';
import BackgroundHaloBlueSVG from './background-halo-blue.svg';
import BackgroundHaloGreenSVG from './background-halo-green.svg';
import SuccessCheckSVG from './success-check.svg';
import OnboardingButton from './OnboardingButton';
import ConcentricCircles from './ConcentricCircles';
import {AnimatePresence, motion} from 'framer-motion';
import {Button /* Link */} from '@nextui-org/react';
import Paywall from './Paywall';

const subPath = ['connect-shopify'];

type OnboardingStage = 'meta' | 'shopify' | 'success';

const Onboarding = memo(() => {
  const centerRef = useRef<HTMLDivElement | null>(null);
  const {isAuthenticated} = useAppContext();
  const {user, isLoading} = useAuthContext();
  const {searchParams} = useSearchParamsContext();
  const {_} = useLingui();
  const {reportMetaAuthStart} = useAnalytics();
  const onLoginCompleted = (data: {href: string}) => {
    if (data.href) {
      window.location.href = data.href;
    }
  };
  const [metaLoginMutation, {loading: metaLoginLoading}] = useMutation(
    META_LOGIN,
    {
      variables: {
        source: 'onboarding',
      },
      onCompleted: onLoginCompleted,
      refetchQueries: [GET_INTEGRATIONS],
    },
  );
  const {data: metaAdsData, loading: metaAdsLoading} = useQuery(
    GET_INTEGRATIONS,
    {
      variables: {
        filter: {
          userId: user?.id,
          type: IntegrationType.metaAds,
        },
        order: {type: 'ASC'},
        first: 1,
      },
      skip: !user?.id,
      fetchPolicy: 'cache-and-network',
    },
  );
  const {data: shopifyData, loading: shopifyLoading} = useQuery(
    GET_INTEGRATIONS,
    {
      variables: {
        filter: {
          userId: user?.id,
          type: IntegrationType.shopify,
        },
        order: {type: 'ASC'},
        first: 1,
      },
      skip: !user?.id,
      fetchPolicy: 'cache-and-network',
    },
  );
  const hasMetaIntegration = Boolean(metaAdsData?.connection.edges.length);
  const hasShopifyIntegration = Boolean(shopifyData?.connection.edges.length);
  const isPageLoading = isAuthenticated
    ? isLoading ||
      !user ||
      (!metaAdsData && metaAdsLoading) ||
      (!shopifyData && shopifyLoading) ||
      (hasMetaIntegration && !user.stripeSubscription)
    : false;
  const stage: OnboardingStage = (() => {
    if (!hasMetaIntegration) {
      return 'meta';
    }

    if (user?.stripeSubscription) {
      return 'success';
    }

    if (!hasShopifyIntegration) {
      return 'shopify';
    }

    return 'success';
  })();
  const metaStage = (() => {
    switch (stage) {
      case 'meta':
        return 'active';
      case 'shopify':
        return 'connected';
      case 'success':
        return 'connected';
      default:
        return 'standby';
    }
  })();
  const shopifyStage = (() => {
    if (stage === 'meta') {
      return 'standby';
    }

    if (hasShopifyIntegration) {
      return 'connected';
    }

    return 'active';
  })();

  if (hasMetaIntegration && user && !user.stripeSubscription) {
    return <Paywall />;
  }

  if (isPageLoading) {
    return (
      <PortalSpinner
        classNames={{
          base: 'backdrop-blur-sm fixed inset-0 z-[1000]',
        }}
        size="lg"
      />
    );
  }

  return (
    <>
      <ModalOutlet basePath={'/onboarding'} subPath={subPath} />
      <ConcentricCircles
        centerRef={centerRef}
        isSuccess={stage === 'success'}
        className={'absolute inset-0 z-0'}
      />
      {/* overflow-hidden since halo svg have min width */}
      <div className="relative z-10 flex w-full grow flex-col items-center overflow-hidden px-5">
        <OnboardingHeader />
        <div className="flex w-full grow flex-col items-center pb-14 md:pb-16">
          <div
            ref={centerRef}
            className="flex w-full max-w-[min(100dvw,42rem)] grow flex-col items-center justify-center">
            <AnimatePresence mode="popLayout">
              {stage === 'success' ? (
                <motion.div
                  key={'success'}
                  initial={{
                    opacity: 0,
                    scale: 0.9,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                  exit={{
                    opacity: 0,
                    scale: 0.9,
                  }}
                  className="relative flex max-h-[310px] items-center justify-center">
                  <img
                    src={BackgroundHaloGreenSVG}
                    alt="background"
                    className="absolute left-1/2 top-1/2 z-0 max-h-[800px] min-h-[800px] min-w-[800px] max-w-[800px] -translate-x-1/2 -translate-y-1/2 opacity-50"
                  />
                  <img
                    src={SuccessCheckSVG}
                    alt="success"
                    className="relative z-10 h-auto w-full max-w-[260px] object-contain"
                  />
                </motion.div>
              ) : (
                <motion.div
                  key={'background'}
                  initial={{
                    opacity: 0,
                    scale: 0.9,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                  exit={{
                    opacity: 0,
                    scale: 0.9,
                  }}
                  className="relative">
                  <img
                    src={BackgroundHaloBlueSVG}
                    alt="background"
                    className="absolute left-1/2 top-1/2 z-0 max-h-[800px] min-h-[800px] min-w-[800px] max-w-[800px] -translate-x-1/2 -translate-y-1/2"
                  />
                  <img
                    src={Background}
                    alt="background"
                    className="relative z-10 max-h-[310px] max-w-[min(412px,100%)]"
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="flex w-full max-w-[min(100dvw,42rem)] flex-col gap-12">
            <div className="text-center text-3xl font-semibold">
              {stage === 'success' ? (
                <Trans>
                  Connected!
                  <br />
                  Sales Magic Incoming
                </Trans>
              ) : (
                <Trans>
                  Two Steps. Zero Hassle.
                  <br />
                  Endless Growth.
                </Trans>
              )}
            </div>
            {/* {stage === 'success' && user?.stripeSubscription ? (
              <div className="flex flex-col items-center gap-3">
                <div className="text-center text-base font-normal text-foreground/50">
                  <Trans>See results through Portal AI app:</Trans>
                </div>

                <Button
                  disableRipple
                  isIconOnly
                  as={Link}
                  isExternal
                  href="https://apps.apple.com/app/portal-ai/id6741169696"
                  className="h-fit w-fit rounded-none bg-transparent p-0">
                  <img src={AppStoreSVG} alt="App Store" />
                </Button>
              </div>
            ) : null} */}
            <div
              className={twMerge(
                'flex justify-center',
                (hasMetaIntegration && hasShopifyIntegration) ||
                  stage === 'meta'
                  ? 'flex-row gap-3'
                  : 'min-h-[7rem] flex-col items-center gap-4',
              )}>
              <OnboardingButton
                icon={metaStage === 'active' ? MetaShadowPNG : MetaPNG}
                iconClassName={twMerge(
                  'w-auto relative',
                  metaStage === 'active'
                    ? 'min-h-[53px] max-h-[53px]'
                    : 'min-h-[26px] max-h-[26px]',
                )}
                state={metaStage}
                isDisabled={
                  metaLoginLoading || metaAdsLoading || hasMetaIntegration
                }
                text={_('Sign in with Meta')}
                ariaLabel={_('Connect Meta Ads')}
                onPress={() => {
                  setIsOnboardingFlow(true);
                  setParamsAfterLogin(searchParams);

                  reportMetaAuthStart().finally(() => {
                    metaLoginMutation();
                  });
                }}
              />
              {stage !== 'meta' && (
                <OnboardingButton
                  icon={
                    shopifyStage === 'active' ? ShopifyShadowPNG : ShopifyPNG
                  }
                  iconClassName={twMerge(
                    'w-auto relative',
                    shopifyStage === 'active'
                      ? 'min-h-[52px] max-h-[52px]'
                      : 'min-h-[26px] max-h-[26px]',
                  )}
                  state={shopifyStage}
                  isDisabled={
                    shopifyLoading || hasShopifyIntegration || !user?.id
                  }
                  text={_('Supercharge for More Sales')}
                  ariaLabel={_('Connect Shopify')}
                  as={RouterLink}
                  // @ts-expect-error typing issue
                  to={'/onboarding/connect-shopify'}
                />
              )}
            </div>
            {user?.stripeSubscription && (
              <div className="flex flex-col items-center gap-3">
                <div className="flex flex-row gap-2.5">
                  <Button
                    disableRipple
                    isIconOnly
                    isDisabled
                    className="h-fit w-fit rounded-none bg-transparent p-0">
                    <img src={AppStoreSVG} alt="App Store" />
                  </Button>
                  <Button
                    disableRipple
                    isIconOnly
                    isDisabled
                    className="h-fit w-fit rounded-none bg-transparent p-0">
                    <img
                      className="grayscale"
                      src={GooglePlaySVG}
                      alt="Google Play"
                    />
                  </Button>
                </div>
                <div className="text-base font-normal text-foreground/50">
                  <Trans>Coming soon</Trans>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

Onboarding.displayName = 'Onboarding';

export default Onboarding;
