// StripeMobileRedirect.jsx
import {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom'; // Assuming React Router

const StripeMobileRedirect = () => {
  const [searchParams] = useSearchParams();
  const success = searchParams.get('success');
  const checkoutSessionId = searchParams.get('checkoutSessionId');
  const platform = searchParams.get('platform');
  const appDeepLink = `portalapp://${platform === 'android' ? '/' : ''}stripe/redirect?success=${success}&checkoutSessionId=${checkoutSessionId}`;

  useEffect(() => {
    // Your app's deep link URL
    // Immediate redirect attempt
    window.location.href = appDeepLink;
  }, [appDeepLink]);

  return (
    <div className="flex h-full w-full grow flex-col items-center justify-center text-center">
      <h1>Redirecting back to the app...</h1>
      <p>
        {"If you're not redirected automatically, "}
        <a href={appDeepLink} className="text-primary">
          click here
        </a>
      </p>
    </div>
  );
};
export default StripeMobileRedirect;
